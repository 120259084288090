.top.attached.label.light {
  background-color: #F8F8F8;
  box-shadow: 0px 0px 0px 1px #DDDDDD;
}

.ui.console-inline.label {
  position: absolute;
  z-index: 100;
  top: 0.8em;
  left: 1em;
  margin: 0em 0em 0em 0em !important;
}

.ui[class*="leftdev address"].input>input
{
  padding-left: 10em !important;
  padding-right: 1em !important;
}

.ui[class*="left address"].input>input
{
  padding-left: 12.5em !important;
  padding-right: 1em !important;
}

.ui.form .success.message, .ui.form .warning.message, .ui.form .error.message {
  display: block;
}
