.blur-bg {
  background: rgba(255,255,255,.15) !important;
  -webkit-backdrop-filter: saturate(180%) blur(5px);
  backdrop-filter: saturate(180%) blur(5px);
}

.large-header {
	position: absolute;
    top:0px;
	width: 100%;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
	z-index: -80;
}
