.CodeMirror {
    height: 600px;
}

.editor-toolbar.fullscreen {
  z-index: 200;
}

.pdf.main {
  font-family:Arial,Helvetica,Verdana,sans-serif !important;
}
